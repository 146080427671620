{
  const hogescrolls = document.querySelectorAll('.scroll-effect')

  // console.log('navi position: ' + navPosi)
   
  window.addEventListener('scroll', ()=> {

    hogescrolls.forEach( (el) => {
      let position = el.getBoundingClientRect().top
  
      if(window.innerHeight > position ) {
        scrollAction(el)
      }
    })
  
    // console.log('window : '+window.pageYOffset);
    // console.log('hoge position : '+position)
  });
  



  function scrollAction(el) {
    el.classList.add('active')
  }
}



{
  const btnPurchase = document.getElementById('btnPurchase')
  const purchase = document.getElementById('purchase')
  
  const btnProfile = document.getElementById('btn-profile')
  const sectionProfile = document.getElementById('profile')
  
  const stocklist = document.getElementById('stocklist')
  const btnStocklist = document.getElementById('btnStocklist')

  btnProfile.addEventListener('click', () => {
    smartScroll(sectionProfile)
  })

  btnPurchase.addEventListener('click', () => {
    smartScroll(purchase)
  })
  
  btnStocklist.addEventListener('click', () => {
    smartScroll(stocklist)
  })

  function smartScroll(target) {
    const offsetTop = window.pageYOffset
    const position = target.getBoundingClientRect().top
    const top = position + offsetTop
    window.scrollTo({
      top,
      behavior: "smooth"
    })
  }
}


// const rectTop = purchase.getBoundingClientRect().top
// const offsetTop = window.pageYOffset

// const buffer = 0
// const top = rectTop + offsetTop - buffer

// btnPurchase.addEventListener('click', () => {
//   window.scrollTo({
//     top,
//     behavior: "smooth"
//   })
// })


// {
//   const btnProfile = document.getElementById('btn-profile')
//   const sectionProfile = document.getElementById('profile')
//   // const offsetTop = window.pageYOffset
  
//   btnProfile.addEventListener('click', () => {
//     const position = sectionProfile.getBoundingClientRect().top
//     const top = position + offsetTop
//     // console.log('position : '+ position)
//     // console.log('window : '+window.pageYOffset);
//     window.scrollTo({
//       top,
//       behavior: "smooth"
//     })
//   })
// }

